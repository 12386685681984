import React from 'react';
import ImageGallery from 'react-image-gallery';
import classNames from 'classnames/bind';
import Image from '../../image/js/image';

const styles = require('../css/gallery.scss');
const cx = classNames.bind(styles);

class Gallery extends React.Component<Props, null> {
	_renderItem = (item) => {
		return (
			<div className="image-gallery-image">
				<Image filename={item.original} />
				{item.description && <span className="image-gallery-description">{item.description}</span>}
			</div>
		);
	};

	render() {
		return (
			<div>
				<section className={cx('m-gallery')}>
					<div className={cx('m-gallery__content')}>
						<ImageGallery
							items={this.props.items}
							showPlayButton={false}
							showNav={false}
							showThumbnails={false}
							lazyLoad={true}
							renderItem={this._renderItem.bind(this)}
						/>
					</div>
				</section>
			</div>
		);
	}
}

export default Gallery;
