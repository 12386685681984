import React from 'react';
import classNames from 'classnames/bind';

const styles = require('../css/text.scss');
const cx = classNames.bind(styles);

class Text extends React.Component<Props, null> {
	render() {
		const className = `${cx(this.props.className, 'a-text')}`;

		return <p className={className}>{this.props.children}</p>;
	}
}

export default Text;
