import React from 'react';
import classNames from 'classnames/bind';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
const styles = require('../css/image.scss');
const cx = classNames.bind(styles);

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Image = (props) => (
	<StaticQuery
		query={graphql`
			query {
				images: allFile {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								sizes(maxWidth: 1008, quality: 90) {
									...GatsbyImageSharpSizes
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => {
			const image = data.images.edges.find((n) => {
				return n.node.relativePath.includes(props.filename);
			});
			if (!image) {
				return null;
			}

			const imageSizes = image.node.childImageSharp.sizes;
			return <Img alt={props.alt} sizes={imageSizes} className={cx('a-image')} />;
		}}
	/>
);

export default Image;
