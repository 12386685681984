import React from 'react';
import classNames from 'classnames/bind';
import { Tooltip } from 'react-tippy';

const star = require('../img/star.svg');
const styles = require('../css/star.scss');
const cx = classNames.bind(styles);

class Star extends React.Component {
	render() {
		return (
			<Tooltip
				className={cx('a-star')}
				style={this.props.customStyles}
				position="right"
				trigger="click"
				html={
					<div className={cx('a-star__content')}>
						<h3>{this.props.title}</h3>
						<p>{this.props.content}</p>
					</div>
				}
			>
				<button type="button" className={cx('a-star__item')}>
					<img className={cx('a-star__image')} src={star} alt={this.props.title} />
				</button>
			</Tooltip>
		);
	}
}

export default Star;
