import React from 'react';
import { graphql } from 'gatsby';
import { siteMetadata } from '../../gatsby-config';
import Layout from '../components/layout/js/layout';
import SEO from '../components/seo/js/seo';
import Headline from '../components/headline/js/headline';
import Text from '../components/text/js/text';
import Table from '../components/table/js/table';
import Logo from '../components/logo/js/logo';
import Navigation from '../components/navigation/js/navigation';
import Intro from '../components/intro/js/intro';
import Slider from '../components/slider/js/slider';
import TheMuffinTops from '../components/themuffintops/js/themuffintops';
import EmbededContainer from '../components/embeded-container/js/embeded-container';
import Gallery from '../components/gallery/js/gallery';
import Instagram from '../components/instagram/js/instagram';

const SurrealismCover = [
	{
		original: 'The_Muffin_Tops_Surrealism.jpg',
		description: 'Surrealism – 2019',
	},
];

const BlueberryCover = [
	{
		original: 'The_Muffin_Tops_Blueberry_Dreams.jpg',
		description: 'Blueberry Dreams – 2015',
	},
];

const Kommune2010SessionCover = [
	{
		original: 'The_Muffin_Tops_Kommune_2010_Session.png',
		description: 'Kommune 2010 Session – 2023',
	},
];

const IndexPage = ({ location, data }) => (
	<Layout location={location}>
		<SEO site={siteMetadata} />
		<div className="g-container g-container--fullwidth g-container--overflow-hidden g-container--push-down-lg">
			<div className="g-row g-no-gutters">
				<div className="g-col-12 g-col-lg-6">
					<Logo />
					<div className="g-container h-d-none h-d-lg-block">
						<div className="g-row">
							<div className="g-col-12">
								<Intro />
							</div>
						</div>
					</div>
				</div>
				<div className="g-col-12 g-col-lg-6">
					<Navigation />
				</div>
			</div>
		</div>
		<div className="g-container g-container--fullwidth g-container--overflow-hidden">
			<div className="g-row">
				<div className="g-col-12">
					<Slider />
				</div>
			</div>
		</div>
		<div className="g-container">
			<div className="g-row">
				<div className="g-col-12 h-d-block h-d-lg-none h-d-xl-none">
					<Intro />
				</div>
			</div>
		</div>
		<hr className="h-d-lg-none h-mb-5 h-mb-lg-0" />
		<div className="g-container g-container--fullwidth g-container--overflow-hidden">
			<div className="g-row">
				<div className="g-col-12">
					<TheMuffinTops />
				</div>
			</div>
		</div>
		<hr />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col-12">
					<section id="musik" className="h-mb-5">
						<Headline size={2} className="h-mt-5">
							Musik
						</Headline>
						<Headline size={3}>Die Muffins live und in Farbe</Headline>
						<Text className="h-mt-4">
							Live-Session im Studio? Da können wir die Wohnzimmer-Atmosphäre in der Kommune 2010 in
							Offenbach nur empfehlen. Im Frühling 2023 haben wir's ausprobiert.
						</Text>
						<div className="g-row">
							<div className="g-col-12">
								<EmbededContainer ratio="16x9">
									<iframe
										allowFullScreen
										width="100%"
										height="auto"
										src="https://www.youtube-nocookie.com/embed/Mx1vLGJv7Qs?&modestbranding=1&autohide=1&controls=1&theme=light&rel=0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										loading="lazy"
									/>
								</EmbededContainer>
							</div>
							<div className="g-col-12 h-mt-3">
								<EmbededContainer ratio="16x9">
									<iframe
										allowFullScreen
										width="100%"
										height="auto"
										src="https://www.youtube-nocookie.com/embed/d2bKhK4bcgw?&modestbranding=1&autohide=1&controls=1&theme=light&rel=0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										loading="lazy"
									/>
								</EmbededContainer>
							</div>
							<div className="g-col-12 h-mt-3">
								<EmbededContainer ratio="16x9">
									<iframe
										allowFullScreen
										width="100%"
										height="auto"
										src="https://www.youtube-nocookie.com/embed/DAAwabzCaPM?&modestbranding=1&autohide=1&controls=1&theme=light&rel=0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										loading="lazy"
									/>
								</EmbededContainer>
							</div>
						</div>
						<Text className="h-mt-4">
							Live-Videos vom Sommer 2019, aus dem guten alten BK - „Berliner Keller“ oder auch „Bester
							Klub“ für Release-Gigs. Wir wissen, wovon wir reden.
						</Text>
						<div className="g-row">
							<div className="g-col-12 g-col-sm-6">
								<EmbededContainer ratio="16x9">
									<iframe
										allowFullScreen
										width="100%"
										height="auto"
										src="https://www.youtube-nocookie.com/embed/hXcZXcbANdg?&modestbranding=1&autohide=1&controls=1&theme=light&rel=0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										loading="lazy"
									/>
								</EmbededContainer>
							</div>
							<div className="g-col-12 g-col-sm-6 h-mt-3 h-mt-sm-0">
								<EmbededContainer ratio="16x9">
									<iframe
										allowFullScreen
										width="100%"
										height="auto"
										src="https://www.youtube-nocookie.com/embed/xdKVw-M2RZE?&modestbranding=1&autohide=1&controls=1&theme=light&rel=0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										loading="lazy"
									/>
								</EmbededContainer>
							</div>
						</div>
						<Headline size={3} className="h-mt-4">
							Die Muffins streamen
						</Headline>
						<div className="g-row h-mb-4">
							<div className="g-col-12 g-col-sm-5 h-mb-4 h-mb-sm-0">
								<Gallery items={Kommune2010SessionCover} />
							</div>
							<div className="g-col-12 g-col-sm-7">
								<Headline size={3}>Kommune 2010 Session</Headline>
								<Text>
									Unser großes Comeback feiern wir nicht mit Pauken und Trompeten, sondern mit einem
									Song über eine schwere Depression. Drei Jahre Corona waren hart, auch für uns. „365“
									hoch drei. Doch keine Sorge: Die nachdenklichen Lyrics werden durch das fetzige
									Gitarrenriff konterkariert. Diesmal keine Studio-Tricksereien, alles live - und mit
									zwei Extra-Songs vom Hitalbum „Surrealism“ obendrauf. Enjoy!
								</Text>
							</div>
						</div>
						<EmbededContainer className="h-mb-4">
							<iframe
								src="https://open.spotify.com/embed/album/5cRfnsyIt13oAr0TSzVkjv"
								width="100%"
								height="400"
								allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
								loading="lazy"
							/>
						</EmbededContainer>
						<div className="g-row h-mb-4">
							<div className="g-col-12 g-col-sm-5 h-mb-4 h-mb-sm-0">
								<Gallery items={SurrealismCover} />
							</div>
							<div className="g-col-12 g-col-sm-7">
								<Headline size={3}>Surrealism</Headline>
								<Text>
									Ein rasanter, freilich nur fragmentarischer Ritt durch die jüngere
									Populärmusikgeschichte. Elektronischer als „Blueberry Dreams“, weil das heute eben
									modern ist. Mitunter klingt „Surrealism“ zwar eher nach den Achtzigern als nach der
									Gegenwart, aber auch das ist ja heute modern. Postmoderne Referenzen auf die
									kulinarische Qualität des gefeierten Erstlings lässt nur „Soul Food“ anklingen,
									wobei schon hier die Wendung vom „Food“ zum „Soul“ auf die hermeneutische Sinnsuche
									der weiteren Songs anspielt. Wir begeben uns mit „Josephine“ in luftige und mit
									„2041“ in noch luftigere Höhen, um am Ende festzustellen: „Has it ever come to your
									mind that we’re just reflections.”
								</Text>
							</div>
						</div>
						<EmbededContainer className="h-mb-4">
							<iframe
								src="https://open.spotify.com/embed/album/622zlQg4s9L4ssgkEHPROv"
								width="100%"
								height="400"
								allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
								loading="lazy"
							/>
						</EmbededContainer>
						<Headline size={3} className="h-mt-4">
							Josephine
						</Headline>
						<Text>
							Als hätte Josephine nicht schon genug Stress in der Schule, will ihr der strenge Papa noch
							den Abend vermiesen. Ob sie es am Ende zur Party schafft?
						</Text>
						<EmbededContainer ratio="16x9">
							<iframe
								allowFullScreen
								width="100%"
								height="auto"
								src="https://www.youtube-nocookie.com/embed/zm_RQrJR2gw?&modestbranding=1&autohide=1&controls=1&theme=light&rel=0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								loading="lazy"
							/>
						</EmbededContainer>
						<div className="g-row h-mb-4 h-mt-4">
							<div className="g-col-12 g-col-sm-5 h-mb-4 h-mb-sm-0">
								<Gallery items={BlueberryCover} />
							</div>
							<div className="g-col-12 g-col-sm-7">
								<Headline size={3}>Blueberry Dreams</Headline>
								<Text>
									Musik soll gut ins Ohr gehen. Gut schmecken darf sie auch. Man halte sich an ein
									rustikales Klangrezept mit schaumig-schokoladig-gequirlter Jazzbesen-Basis, füge
									butterweich-blubbernde Rhodes-Riffs und luftig-leichte Gitarren-Licks hinzu und
									garniere die Kreation mit einem poppigen Tenor-Topping. Fertig sind die „Blueberry
									Dreams“. Zugegeben, mittlerweile servieren wir exquisitere Häppchen, aber wir
									schnuppern gerne in die frühen Hausmannskost-Zeiten zurück. Das duftet so schön wie
									damals, wenn die Oma einen gedeckten Apfelkuchen im Ofen hatte. Hach.
								</Text>
							</div>
						</div>
						<EmbededContainer>
							<iframe
								src="https://open.spotify.com/embed/album/1i8JI8NZUEa6WEXbUhRVUX"
								width="100%"
								height="400"
								allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
								loading="lazy"
							/>
						</EmbededContainer>
						<Headline size={3} className="h-mt-4">
							No Clue
						</Headline>
						<Text>
							Alles fing im eigenen Wohnzimmer an, auch das erste Musikvideo. Damals hatten wir „No Clue“,
							wie stressig das Leben als Musiker doch ist.
						</Text>
						<EmbededContainer ratio="16x9">
							<iframe
								allowFullScreen
								width="100%"
								height="auto"
								src="https://www.youtube-nocookie.com/embed/U9pio0G4K_s?&modestbranding=1&autohide=1&controls=1&theme=light&rel=0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								loading="lazy"
							/>
						</EmbededContainer>
						<Headline size={3} className="h-mt-5">
							Follow the Muffin Tops
						</Headline>

						<div className="g-row">
							<div className="g-col-12 g-col-md-3 h-mb-5 h-sm-sm-0 h-d-flex h-justify-content-center h-justify-content-sm-start h-align-items-center">
								<a
									href="https://www.facebook.com/muffintopsmusic"
									target="_blank"
									className="a-icon a-icon--facebook"
								>
									<i className="a-icon__icon" />
									<span className="a-icon__text">Facebook</span>
								</a>
							</div>
							<div className="g-col-12 g-col-md-3 h-mb-5 h-sm-sm-0 h-d-flex h-justify-content-center h-justify-content-sm-start h-align-items-center">
								<a
									href="https://www.instagram.com/muffintopsmusic/"
									target="_blank"
									className="a-icon a-icon--instagram"
								>
									<i className="a-icon__icon" />
									<span className="a-icon__text">Instagram</span>
								</a>
							</div>
							<div className="g-col-12 g-col-md-3 h-mb-5 h-sm-sm-0 h-d-flex h-justify-content-center h-justify-content-sm-start h-align-items-center">
								<a
									href="https://open.spotify.com/artist/6sMPzr9evEK1JsA5vRz37c"
									target="_blank"
									className="a-icon a-icon--spotify"
								>
									<i className="a-icon__icon" />
									<span className="a-icon__text">Spotify</span>
								</a>
							</div>
							<div className="g-col-12 g-col-md-3 h-mb-5 h-sm-sm-0 h-d-flex h-justify-content-center h-justify-content-sm-start h-align-items-center">
								<a
									href="https://www.youtube.com/user/muffintopsmusic"
									target="_blank"
									className="a-icon a-icon--youtube"
								>
									<i className="a-icon__icon" />
									<span className="a-icon__text">YouTube</span>
								</a>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
		<hr />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col-12">
					<section id="termine" className="h-mb-5">
						<Headline size={2} className="h-mt-5">
							Termine
						</Headline>
						<Table>
							<table>
								<thead>
									<tr>
										<th scope="col">Wann?</th>
										<th scope="col">Warum?</th>
										<th scope="col">Mehr Informationen!</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">25.05.2024</th>
										<td>Berger Straßenfest</td>
										<td>Detailinformationen folgen</td>
									</tr>
									<tr>
										<th scope="row">26.08.2023, 19:00</th>
										<td>FOODTOPIA MyZeil</td>
										<td>
											<a
												href="https://www.myzeil.de/foodtopia-entertainment/foodtopia/"
												target="_blank"
											>
												Mehr Informationen auf der MyZeil-Website
											</a>
										</td>
									</tr>
									<tr>
										<th scope="row">25.06.2023, 14:00</th>
										<td>
											Steinbruch Festival mit Mate Power, Gastone, the Argosonics und jazzmadass
										</td>
										<td>
											<a href="https://www.artifly.de/steinbruchfestival.html" target="_blank">
												Mehr Informationen auf der Festival-Website
											</a>
										</td>
									</tr>
									<tr>
										<th scope="row">01.06.2023, 19:00</th>
										<td>
											Berliner Keller mit{' '}
											<a href="https://www.facebook.com/leolixl/" target="_blank">
												LeoLixl
											</a>
										</td>
										<td>
											<a
												href="https://www.songkick.com/concerts/41135493-muffin-tops-at-berliner-keller"
												target="_blank"
											>
												Veranstaltung auf Songkick
											</a>
										</td>
									</tr>
									<tr>
										<th scope="row">25.01.2020, 20:00</th>
										<td>
											Souleskalation mit Lube und Madhouse Matadors im Café Kaktus
											Friedberg/Hessen
										</td>
										<td>
											<a href="https://www.facebook.com/events/372077203733953" target="_blank">
												Veranstaltung auf Facebook
											</a>
										</td>
									</tr>
									<tr>
										<th scope="row">14.12.2019, 20:00</th>
										<td>Souleskalation mit Lube und Madhouse Matadors</td>
										<td>
											<a href="https://www.facebook.com/events/2306102979703445" target="_blank">
												Veranstaltung auf Facebook
											</a>
										</td>
									</tr>
									<tr>
										<th scope="row">31.08.2019, ca. 20:00</th>
										<td>Rottweiler-Platz-Fest</td>
										<td>
											<a href="https://gudeleut.wordpress.com/" target="_blank">
												Gude Leut‘ e.V.
											</a>
										</td>
									</tr>
									<tr>
										<th scope="row">07.06.2019, ca. 20:00</th>
										<td>The Muffin Tops Album-Release-Party "Surrealism"</td>
										<td>
											<a href="http://berlinerbar.com/" target="_blank">
												Berliner Keller
											</a>
											,{' '}
											<a href="https://www.facebook.com/events/699305107153923/" target="_blank">
												Facebook
											</a>
										</td>
									</tr>
									<tr>
										<th scope="row">27.04.2019, 17:00</th>
										<td>Boom! Music 2019</td>
										<td>
											<a href="https://www.boom-designmarkt.com/boom-music" target="_blank">
												Boom! Music
											</a>
											,{' '}
											<a href="https://www.facebook.com/events/358517221597066/" target="_blank">
												Facebook
											</a>
										</td>
									</tr>
									<tr>
										<th scope="row">27.04.2019, 13:00</th>
										<td>Boom! Music 2019</td>
										<td>
											<a href="https://www.boom-designmarkt.com/boom-music" target="_blank">
												Boom! Music
											</a>
											,{' '}
											<a href="https://www.facebook.com/events/358517221597066/" target="_blank">
												Facebook
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</Table>
					</section>
				</div>
			</div>
		</div>
		<hr />
		<div className="g-container">
			<section id="social" className="h-mb-5">
				<Headline size={2} className="h-mt-5">
					Die letzten Muffin Posts auf{' '}
					<a href="https://www.instagram.com/muffintopsmusic/" target="_blank">
						Instagram
					</a>
				</Headline>
				<Instagram nodes={data.allInstagramContent} />
			</section>
		</div>
	</Layout>
);

export default IndexPage;

export const query = graphql`
	query pageQuery {
		allInstagramContent(limit: 24) {
			edges {
				node {
					id
					caption
					permalink
					localFile {
						childImageSharp {
							fluid(quality: 70, maxWidth: 600, maxHeight: 600) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		}
	}
`;
