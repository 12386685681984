import React from 'react';
import classNames from 'classnames/bind';

const styles = require('../css/table.scss');
const cx = classNames.bind(styles);

class Table extends React.Component<Props, null> {
	render() {
		return <div className="a-table a-table--table-striped">{this.props.children}</div>;
	}
}

export default Table;
