import React from 'react';
import ImageGallery from 'react-image-gallery';
import classNames from 'classnames/bind';
import Image from '../../image/js/image';

const styles = require('../css/slider.scss');
const cx = classNames.bind(styles);

class Slider extends React.Component {
	_renderItem = (item) => {
		return (
			<div className="image-gallery-image">
				<Image filename={item.original} />

				{item.description && <span className="image-gallery-description">{item.description}</span>}
			</div>
		);
	};

	_renderThumbInner = (item) => {
		return (
			<div className="image-gallery-thumbnail-inner">
				<Image filename={item.original} alt={item.originalAlt} title={item.originalTitle} />
			</div>
		);
	};

	render() {
		const images = [
			{
				original: 'The_Muffin_Tops_Intro_1.jpg',
			},
			{
				original: 'The_Muffin_Tops_Intro_2.jpg',
			},
			{
				original: 'The_Muffin_Tops_Intro_3.jpg',
			},
			{
				original: 'The_Muffin_Tops_Intro_4.jpg',
			},
			{
				original: 'The_Muffin_Tops_Intro_5.jpg',
			},
		];

		return (
			<section className={cx('m-slider')}>
				<div className={cx('m-slider__content')}>
					<ImageGallery
						autoPlay={true}
						slideDuration={1500}
						slideInterval={5000}
						items={images}
						showPlayButton={false}
						showFullscreenButton={false}
						showNav={false}
						lazyLoad={true}
						renderThumbInner={this._renderThumbInner.bind(this)}
						renderItem={this._renderItem.bind(this)}
					/>
				</div>
			</section>
		);
	}
}

export default Slider;
