import React from 'react';
import classNames from 'classnames/bind';
import Img from 'gatsby-image';

const styles = require('../css/instagram.scss');
const cx = classNames.bind(styles);

const Node = ({ node }) => (
	<div className="g-col-12 g-col-md-6 g-col-lg-3">
		<article className={cx('m-instagram')}>
			<a href={node.permalink} target="_blank" className={cx('m-instagram__item')}>
				<Img fluid={node.localFile.childImageSharp.fluid} />
				<div className={cx('m-instagram__overlay h-d-flex h-align-items-center h-justify-content-center')}>
					<div className={cx('m-instagram__caption')}>
						<span className={cx('m-instagram__caption-text')}>{node.caption}</span>
					</div>
				</div>
			</a>
		</article>
	</div>
);

const Instagram = ({ nodes }) => {
	return (
		<div className="g-row g-no-gutters">
			{nodes.edges.map((instagram) => (
				<Node key={instagram.node.id} node={instagram.node} />
			))}
		</div>
	);
};

export default Instagram;
