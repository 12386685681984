import React from 'react';
import classNames from 'classnames/bind';
import Star from '../../star/js/star';
import Image from '../../image/js/image';
import Headline from '../../headline/js/headline';
import Text from '../../text/js/text';

const styles = require('../css/themuffintops.scss');
const cx = classNames.bind(styles);

// eslint-disable-next-line react/prefer-stateless-function
class TheMuffinTops extends React.Component {
	render() {
		const starWidth = '25px';
		const stars = {
			daniel: {
				customStyles: {
					top: `calc(70% - ${starWidth})`,
					left: `calc(14% - ${starWidth})`,
				},
				title: 'Daniel',
				content: `Verschwendet sein schlagzeugerisches Talent an ein musikalisches Quintett, dessen Kompositionen sich meist mit Four-on-the-floor begnügen. Lässt sich nichts anmerken, weil Four-on-the-floor eben auch einfach Megabock macht.`,
			},
			patrick: {
				customStyles: {
					top: `calc(56% - ${starWidth})`,
					left: `calc(30.5% - ${starWidth})`,
				},
				title: 'Patrick',
				content: `Nachweislich eins der tightesten Goldkehlchen des Planeten und musikalisch sowie songwriterisch ganz schön auf Zack, auch wenn er einen Des-Moll7 nicht von einem halbverminderten Gis-Dur unterscheiden kann. It's all about feeling, baby.`,
			},
			markus: {
				customStyles: {
					top: `calc(60% - ${starWidth})`,
					left: `calc(47% - ${starWidth})`,
				},
				title: 'Markus',
				content: `Hampelt so mitreißend auf der Bühne rum, dass er immer Gefahr läuft, den Einsatz für seine sekundäre Kompetenz - das Bassspiel - zu verpassen. Lacht den Fauxpas so charmant weg, dass man ihm nicht böse sein kann.`,
			},
			david: {
				customStyles: {
					top: `calc(64% - ${starWidth})`,
					left: `calc(63.5% - ${starWidth})`,
				},
				title: 'David',
				content: `War bei seinem Einstand als neuer Gitarrist der Muffin Tops erstmal perplex, wie albern erwachsene Menschen sein können. Hat sich schnell akklimatisiert. Begeistert mit tighten Riffs, schraubt sonst oft am Pedalboard rum oder dudelt in Gesprächspausen rein, wie es sich für Gitarristen gehört.`,
			},
			paul: {
				customStyles: {
					top: `calc(53% - ${starWidth})`,
					left: `calc(82% - ${starWidth})`,
				},
				title: 'Paul',
				content: `Findet seit seinem Wechsel von der Gitarre ans Keyboard plötzlich nicht mehr, dass die Gitarre das lauteste Instrument sein sollte, sondern das Keyboard. Verzögert die Proben mit stundenlangen Reparaturen an der Technik, ist sich aber keiner Schuld bewusst: „Alles richtig eingestellt, muss an Deinem Setup liegen.“`,
			},
		};

		return (
			<section className={cx('m-themuffintops')} id="band">
				<Headline size={2} className="h-ml-4">
					Band – Bandmitglieder im Rampenlicht
				</Headline>
				<Text className="h-ml-4">Gestatten: die Mitglieder der Muffin Tops.</Text>
				<div className={cx('m-themuffintops__content')}>
					<Star
						customStyles={stars.daniel.customStyles}
						title={stars.daniel.title}
						content={stars.daniel.content}
					/>
					<Star
						customStyles={stars.patrick.customStyles}
						title={stars.patrick.title}
						content={stars.patrick.content}
					/>
					<Star
						customStyles={stars.markus.customStyles}
						title={stars.markus.title}
						content={stars.markus.content}
					/>
					<Star
						customStyles={stars.david.customStyles}
						title={stars.david.title}
						content={stars.david.content}
					/>
					<Star
						customStyles={stars.paul.customStyles}
						title={stars.paul.title}
						content={stars.paul.content}
					/>
					<Image filename="The_Muffin_Tops_Band.jpg" />
				</div>
			</section>
		);
	}
}

export default TheMuffinTops;
