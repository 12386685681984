import React from 'react';
import classNames from 'classnames/bind';

type Props = {
	children: any;
	className: string;
	/**
	 * Headline size e.g. 1 for <h1>
	 */
	ratio?: '16x9' | '4x3';
};

const styles = require('../css/embeded-container.scss');
const cx = classNames.bind(styles);

class EmbededContainer extends React.Component<Props, null> {
	render() {
		const ratio = this.props.ratio === undefined ? 'fixed' : this.props.ratio;
		const className = `${cx(this.props.className, 'a-embeded-container')} ${cx('a-embeded-container')}--${ratio}`;

		return <div className={className}>{this.props.children}</div>;
	}
}

export default EmbededContainer;
